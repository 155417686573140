import React from 'react';

import { FAQSection, BenefitsSection } from 'src/sections';
import { Page } from 'src/components';
import paths from 'src/constants/paths';

/**
 * @todo finish benefits pages
 */
export default function Benefits() {
  return (
    <Page title="Catch Benefits" path={paths.BENEFITS} image="/graphics/benefits.png">
      <BenefitsSection title="We handle all of it so you don’t have to" />
      <FAQSection />
    </Page>
  );
}
